import React from 'react';
import Footer from './footer.jsx';
import { Link } from 'react-router-dom';
import Video from './videoPlayer.jsx';
import { Row, Col, Button, Container, Form, FormGroup, Label, Input, Table, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
export default class MainScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resorts: {},
      activeTab: '1'
    };

  }

  componentDidMount() {
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/getResorts')
      .then(res => res.json())
      .then(data => {
        const state = {};
        state.mb = data.map(resort => {
          if (resort.City === 'Myrtle Beach') {
            return resort.otherName;
          }
        });
        state.nmb = data.map(resort => {
          if (resort.City === 'North Myrtle Beach') {
            return resort.otherName;
          }
        });
        state.mi = data.map(resort => {
          if (resort.City === 'Murrells Inlet') {
            return resort.otherName;
          }
        });
        state.sb = data.map(resort => {
          if (resort.City === 'Surfside Beach') {
            return resort.otherName;
          }
        });
        const mb = state.mb.filter(resort => resort !== undefined);
        const nmb = state.nmb.filter(resort => resort !== undefined);
        const mi = state.mi.filter(resort => resort !== undefined);
        const sb = state.sb.filter(resort => resort !== undefined);
        state.mb = mb;
        state.nmb = nmb;
        state.mi = mi;
        state.sb = sb;
        this.setState({ resorts: state });
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  renderTabs() {
    const mb = this.state.resorts.mb.map((resort, key) => {
      return (<li key={resort + '' + key}>{resort}</li>);
    });
    const nmb = this.state.resorts.nmb.map((resort, key) => {
      return (<li key={resort + '' + key}>{resort}</li>);
    });
    const mi = this.state.resorts.mi.map((resort, key) => {
      return (<li key={resort + '' + key}>{resort}</li>);
    });
    const sb = this.state.resorts.sb.map((resort, key) => {
      return (<li key={resort + '' + key}>{resort}</li>);
    });
    return (
      <Container>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                Myrtle Beach
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                North Myrtle Beach
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                Murrells Inlet
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}
              >
                Southside Beach
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <ul>
                    {mb}
                  </ul>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <ul>
                    {nmb}
                  </ul>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <ul>
                    {mi}
                  </ul>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <ul>
                    {sb}
                  </ul>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    );
  }

  renderTable() {
    const less10 = '1 - 9';
    const more10 = '10 +';
    return (
      <Table className='mb-5 hide-on-med-and-down' bordered striped>
        <thead style={{ backgroundColor: 'rgb(46, 162, 225)', color: 'white' }}>
          <tr>
            <th></th>
            <th className='text-center'>
              <h4 className='bold'>Business</h4>
              <h5 className='price-th bold'>$250 / User / Year</h5>
            </th>
            <th className='text-center'>
              <h4 className='bold'>Corporate</h4>
              <h5 className='price-th bold'>$200 / User / Year</h5>
            </th>
          </tr>
        </thead>
        <tbody className='bold'>
          <tr>
            <td>Discount</td>
            <td className='check'>0%</td>
            <td className='check'>20%</td>
          </tr>
          <tr>
            <td>Users</td>
            <td className='check'>{less10}</td>
            <td className='check'>{more10}</td>
          </tr>
          <tr>
            <td>100 + Resorts</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Complete Owner Information</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Unlimited Downloads</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Unlimited Searches</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Account Management</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Access To Site Updates</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Access To Weekly Resort Updates</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td></td>
            <td className='check'><Button tag={Link} to='/signup' color='success' block>Sign Up</Button></td>
            <td className='check'><Button tag={Link} to='/signup' color='success' block>Sign Up</Button></td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const table = this.renderTable();
    let tabs = null;
    if (this.state.resorts.mb) {
      tabs = this.renderTabs();
    }
    return (
      <div id='home' className="mainscreen-bg">
        <Row className='dead-center text-center' style={{ height: '85vh' }}>
          <Col sm='12'>
            <Container>
              <Row>
                <Col sm='12' md={{ size: 6, offset: 3 }}>
                  <h1 className='theme-text-dark text-shadow-white bold landing-top'>B2Listings</h1>
                </Col>
              </Row>
              <Row>
                <Col sm='12' md={{ size: 6, offset: 3 }}>
                  <h4 className='theme-text-dark text-shadow-white'>Get Condo Owner Data With One Click Of A Button</h4>
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>
        <Row style={{ height: '15vh' }}>
          <Col className='text-center bg-theme-color' sm='12' style={{ height: '100%' }}>
            <h1 className='dead-center bold text-white bg-theme-color' style={{ height: '100%' }}>What is B2 Listings?</h1>
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'white', height: 'fit-content' }}>
          <Container>
            <Col sm='12' md={{ size: 8, offset: 2 }}>
              <p className='pt-5 pb-5' style={{ fontSize: '1.2rem' }}>
                A realtor’s first stop to getting condo owner data instantly for resorts in the Myrtle Beach, North
                Myrtle Beach, and Murrells Inlet areas.
                <br /><br />
                Realtors don’t waste time looking up owner data on city websites, just sign up for B2 Listing
service and let us do the work for you. Our easy to use interface and design to quickly find
condo owner information so you can focus on getting the listing and consider it sold.
                <br /><br />
                With our advanced filters, finding your resort is quick and easy, so you can download the owner
data and view it in your favorite spreadsheet program.
              </p>
            </Col>
          </Container>
        </Row>
        <Row id='works' style={{ height: '15vh' }}>

          <Col className='text-center' sm='12' style={{ backgroundColor: 'white', height: '100%' }}>
            <Container>
              <hr />
            </Container>
            <h1 className='dead-center bold theme-text-darkr' style={{ height: '100%' }}>How Does It Work?</h1>
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'white', height: '100%' }}>
          <Col className='text-center pt-5 pb-5' sm='12' md={{ size: 6, offset: 1 }}>
            <Video className='' style={{ boxShadow: '0 0 15px black' }} autoPlay loop src="/images/B2Listings.mp4" />
          </Col>
          <Col className='text-center pt-5 pb-5' sm='12' md={{ size: 5 }}>
            <h1>Its As Easy As</h1>
            <br />
            <h1 className='bold text-white theme-easy-bg dead-center mr-auto ml-auto'>1</h1>
            <p className='bold'>Select Your City</p>
            <br />
            <h1 className='bold text-white theme-easy-bg dead-center mr-auto ml-auto'>2</h1>
            <p className='bold'>Select Your Resort</p>
            <br />
            <h1 className='bold text-white theme-easy-bg dead-center mr-auto ml-auto'>3</h1>
            <p className='bold'>Download The Report</p>
          </Col>
        </Row>
        <Row id='resorts' style={{ height: '15vh' }}>
          <Col className='text-center' sm='12' style={{ backgroundColor: 'white', height: '100%' }}>
            <Container>
              <hr />
            </Container>
            <h1 className='dead-center bold theme-text-dark' style={{ height: '100%' }}>What Resorts Have Reports?</h1>
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'white', height: '100%' }}>
          <Col className='pt-5 pb-5' sm='12' md={{ size: 8, offset: 2 }}>
            {tabs}
          </Col>
        </Row>
        <Row id='price' style={{ height: '15vh' }}>
          <Col className='text-center' sm='12' style={{ backgroundColor: 'white', height: '100%' }}>
            <Container>
              <hr />
            </Container>
            <h1 className='dead-center bold theme-text-dark' style={{ height: '100%' }}>Pricing And Plans</h1>
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'white', height: '100%' }}>
          <Col className='pt-5 pb-5' sm='12' md={{ size: 8, offset: 2 }}>
            {table}
          </Col>
        </Row>
        <Row style={{ height: '15vh' }}>
          <Col className='text-center bg-theme-color' sm='12' style={{ height: '100%' }}>
            <h1 className='dead-center bold text-white' style={{ height: '100%' }}>Contact Us</h1>
          </Col>
        </Row>
        <Row className='pb-5 bg-theme-color' style={{ height: 'fit-content' }}>
          <Container>
            <Col sm='12' md={{ size: 6, offset: 3 }}>
              <Form>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label className='text-white' for="exampleEmail">Email</Label>
                      <Input className='' type="email" name="email" id="exampleEmail" placeholder="Email Address" />
                    </FormGroup>
                    <FormGroup>
                      <Label className='text-white' for="fullname">Full Name</Label>
                      <Input className='' type="text" name="fullname" id="fullname" placeholder="Full Name" />
                    </FormGroup>
                    <FormGroup>
                      <Label className='text-white' for="phone">Phone Number</Label>
                      <Input className='' type="tel" name="phone" id="phone" placeholder="555-555-5555" />
                    </FormGroup>
                    <FormGroup>
                      <Label className='text-white' for="phone">Message</Label>
                      <Input className='' type="textarea" name="message" id="message" />
                    </FormGroup>
                    <FormGroup>
                      <Button color='info' onClick={() => { event.preventDefault(); }}>Send</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Container>
        </Row>
        <Footer />
      </div>
    );
  }
}
