import React from 'react';
import { Col, Row, Container } from 'reactstrap';
export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    return (
      <Container>
        <Row style={{ height: '30vh' }}></Row>
        <Row style={{ height: '40vh' }}>
          <Col sm='12' md={{ size: 6, offset: 1 }}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit eum aliquam ipsam odio tempore praesentium aut placeat itaque sequi quod quam dicta ut adipisci obcaecati, eveniet nemo sed consequuntur eligendi quasi provident modi aliquid dolores! Repellat et omnis, ea cum laboriosam rerum nulla aliquam, expedita, illo assumenda tenetur possimus corrupti voluptatum sed! Quibusdam eaque rerum ipsum facere ea perferendis dolorem a minus ut mollitia? Asperiores nostrum molestias quasi sunt similique sit possimus, eos odio? Rem exercitationem quibusdam hic maxime consectetur doloribus libero magni, sapiente temporibus soluta assumenda dicta recusandae consequatur nemo voluptates molestias mollitia aliquam eveniet adipisci nihil necessitatibus harum.
          </Col>
          <Col sm='12' md='4'>

          </Col>
        </Row>

      </Container>
    );

  }
}
