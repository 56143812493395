import React from 'react';
export default class Video extends React.Component {
  render() {
    if (this.props.width === undefined) {
      var width = '100%';
    } else {
      width = this.props.width;
    }
    if (this.props.height === undefined) {
      var height = 'fit-content';
    } else {
      height = this.props.height;
    }
    if (this.props.src === undefined) {
      console.error('You Must Include A "src" prop with your video. Video.js requires a source file.');
    }
    if (this.props.preload === undefined) {
      var preload = 'auto';
    } else {
      preload = this.props.preload;
    }
    if (this.props.id === undefined) {
      var id = 'my-video';
    } else {
      id = this.props.id;
    }
    if (this.props.className === undefined) {
      var classes = 'video-js';
    } else {
      classes = this.props.className + ' ' + 'video-js';
    }
    return (
      <video id={id} className={classes} preload={preload} width={width} height={height} data-setup="{}" {...this.props}>
        <source src={this.props.src} type="video/mp4" />
      </video >
    );
  }
}
