import React from 'react';
import Footer from './footer.jsx';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, Row, Col, Container, Alert, Spinner, NavLink } from 'reactstrap';
import * as Cookie from 'js-cookie';
import * as CryptoJS from 'crypto-js';
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      btnDisable: true,
      btnColor: 'secondary',
      errorAlert: null,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.getElementById('email').focus();
  }

  handleSubmit() {
    event.preventDefault();
    this.setState({ loading: true });
    const loginBtn = document.getElementById('loginBtn');
    loginBtn.disabled = true;

    const settings = {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify({ email: this.state.email, password: this.state.pass })
    };
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/login', settings)
      .then(response => response.json())
      .then(data => {
        if (data === 'wrong password') {
          const errorAlert = (
            <Alert color="danger">
              Wrong Username Or Password
            </Alert>
          );
          loginBtn.disabled = false;
          this.setState({ errorAlert: errorAlert, loading: false });
        } else if (data.success === 'true') {
          const log = CryptoJS.AES.encrypt('true', this.state.email).toString();
          const type = CryptoJS.AES.encrypt(data.status, this.state.email).toString();
          Cookie.set('type', type);
          Cookie.set('usere', this.state.email);
          Cookie.set('usern', data.firstName);
          Cookie.set('log', log);
          this.props.setUser();
          document.getElementById('loginlink').click();
        } /* else if (data === 'wrong password') {
          const errorAlert = (
            <Alert color="danger">
              Wrong Username Or Password
            </Alert>
          );
          loginBtn.disabled = false;
          loginBtn.innerHTML = 'Login';
          this.setState({ errorAlert: errorAlert, loading: false });
        } */
      })
      .catch(err => {
        alert(err);
      });
  }

  handleChange() {
    const name = event.target.name;
    const value = event.target.value;
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  render() {
    if (this.state.email !== '' && this.state.pass !== '') {
      var btnDisable = false;
      var btnColor = 'primary';
    } else {
      btnDisable = true;
      btnColor = 'secondary';
    }
    if (this.state.loading) {
      var button = (
        <Button id='loginBtn' onClick={this.handleSubmit} disabled={btnDisable} color={btnColor} block>
          <Spinner className="" color="light" />
        </Button>
      );
    } else {
      button = (<Button id='loginBtn' onClick={this.handleSubmit} disabled={btnDisable} color={btnColor} block>Login</Button>);
    }
    var errorAlert = this.state.errorAlert;
    return (
      <div className="">
        <Container style={{ height: '100vh' }}>
          <Row style={{ height: '20vh' }}>
          </Row>
          <Row style={{ height: '80vh' }}>
            <Col sm='12' md={{ size: 6, offset: 3 }} style={{ height: '100%' }}>
              <div>
                <Form style={{ boxShadow: '0 0 20px grey', borderRadius: '30px' }} className='w-75 ml-auto mr-auto'>
                  <FormGroup style={{ backgroundColor: '#0D9DFE', borderTopRightRadius: '30px', borderTopLeftRadius: '30px', height: '120px' }} className='text-center dead-center text-white bold'>
                    <h1>Login</h1>
                  </FormGroup>
                  <br />
                  <FormGroup className='w-75 ml-auto mr-auto'>
                    <Label className='bold'>
                  Email Address
                    </Label>
                    <Input id='email' name='email' value={this.state.email} onChange={this.handleChange} placeholder='email@email.com'></Input>
                  </FormGroup>
                  <FormGroup className='w-75 ml-auto mr-auto'>
                    <Label className='bold'>
                  Password
                    </Label>
                    <Input onKeyUp={() => {
                      if (event.keyCode === 13 && btnDisable === false) {
                        this.handleSubmit();
                      }
                    }} name='pass' value={this.state.pass} onChange={this.handleChange} type='password' placeholder='password'></Input>
                  </FormGroup>
                  <FormGroup className='w-75 ml-auto mr-auto mt-4'>
                    <h6 style={{ textAlign: 'right', fontSize: '.9rem' }} className=''>Forgot Username / Password</h6>
                    {button}
                  </FormGroup>
                  <FormGroup className='w-75 ml-auto mr-auto pb-5'>
                    <NavLink id='loginlink' tag={Link} to='/dashboard'></NavLink>
                    {errorAlert}
                  </FormGroup>

                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );

  }
}
