// import * as CryptoJS from 'crypto-js';
export function makeCSV(data) {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));
  for (const row of data) {
    const values = headers.map(header => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      if (header === 'Last_Sale_Date') {
        const escapedtime = escaped.slice(0, 10);
        return `"${escapedtime}"`;
      }
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  const readyDownload = csvRows.join('\n');
  return readyDownload;
}

export function download(filename, data, filetype = 'csv') {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
  const file = filename + '.' + filetype;
  element.setAttribute('download', file);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

}
export function getQuery(query) {
  const urlParams = new URLSearchParams(window.location.search);
  const foundQuery = urlParams.get(query);
  return foundQuery;
}
/* export function encrypt(value, email) {
  if (typeof (value) === 'string') {
  return CryptoJS.AES.encrypt(value, email).toString();
  } else {
    return CryptoJS.AES.encrypt(JSON.stringify(value), email).toString();
  }
}
export function decrypt(value, email) {
  const toDecrypt = CryptoJS.AES.decrypt(value, email);
  const decrypted = toDecrypt.toString(CryptoJS.enc.Utf8);
  if (decrypted.charAt(0) === '{') {
    return JSON.parse(decrypted);
  }
  return decrypted;
} */
