import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse, Button, CardBody, Card, Container, Row, Col, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import * as Cookie from 'js-cookie';
export default class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen1: true,
      isOpen2: false,
      isOpen3: false,
      accountNext: false,
      agreeCheck: false,
      infoNext: false,
      infoCheck: false,
      validPassword: null,
      slide: 1,
      loading: false,
      list: []

    };
    this.capFirstLetter = this.capFirstLetter.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.checkPasswords = this.checkPasswords.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const emailList = JSON.parse(Cookie.get('list'));
    this.setState({ list: emailList.emails });
    // document.getElementById('email').value = Cookie.get('firstemail');
  }

  handleSubmit() {
    event.preventDefault();
    this.setState({ loading: true });
    var redirectBtn = document.getElementById('signup');
    redirectBtn.disabled = true;
    var urlParams = new URLSearchParams(window.location.search);
    const settings = {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify({
        users: this.state.list,
        company: document.getElementById('companyName').value,
        session: urlParams.get('session_id')
      })
    };
    // if (true) {
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/signUp', settings);
    this.toggleNext();
    Cookie.remove('list');
    Cookie.remove('firstemail');
    // }

  }

  toggleNext() {
    if (this.state.isOpen1) {
      this.setState({ isOpen1: false, isOpen2: true, slide: 2 });
    } else if (this.state.isOpen2) {
      this.setState({ isOpen2: false, isOpen3: true, slide: 3 });
    }
  }

  handleCheck() {
    this.setState({ agreeCheck: !this.state.agreeCheck });

  }

  capFirstLetter() {
    const fletter = event.target.value.charAt(0).toUpperCase();
    const newValue = event.target.value.replace(event.target.value.charAt(0), fletter);
    event.target.value = newValue;
  }

  checkPasswords() {
    const pw1 = document.getElementById('password').value;
    const pw2 = event.target.value;
    if (pw1 === pw2) {
      this.setState({ validPassword: true });
    } else {
      this.setState({ validPassword: false });
    }
  }

  /*   checkBoxes() {
    const elems = document.getElementsByTagName('input');
    const elemLength = elems.length;
    for (let i = 0; i < elemLength; i++) {
      if (!elems[i].value) {
        return true;
      }
      return false;
    }
  } */

  slideOne() {

    if (this.state.loading) {
      var button = (
        <Button id='signup' style={{ float: 'right' }} onClick={this.handleSubmit} color='primary'><Spinner className="" color="light" /></Button>
      );
    } else {
      button = (<Button id='signup' style={{ float: 'right' }} disabled={!this.state.agreeCheck} onClick={this.handleSubmit} color='primary'>Finish Sign Up</Button>);
    }

    return (
      <div className='dead-center' style={{ height: '100vh' }}>
        <Card>
          <CardBody>
            <h3 className='text-center'>Payment Successful</h3>
            <hr />
            <FormGroup>
              <Label for="companyName">Company Name</Label>
              <Input type="text" name="companyName" id="companyName" placeholder="Company Name" onBlur={this.capFirstLetter} />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input name='agree' checked={this.state.agreeCheck} onChange={this.handleCheck} type="checkbox" />{' '}
                  I Agree To The <a href='/' onClick={() => { event.preventDefault(); }}>Terms Of Service</a>
              </Label>
            </FormGroup>
            <br />
            {button}
          </CardBody>
        </Card>
        <NavLink id='loginlink' tag={Link} to='/dashboard'></NavLink>
      </div>
    );
  }

  slideTwo() {
    return (
      <div>
        <Card>
          <CardBody>
            <h3 className='text-center'>Your Company Account Was Created</h3>
            <hr />
            <p>You should soon recive an email to create your personal account. If the email does not arrive within 30 minutes, contact us at support@b2listings.com</p>
            <div className='dead-center'>
              <Button id='redirect' style={{ float: 'right' }} onClick={() => {
                document.getElementById('loginlink').click();
              }} color='primary'>Redirect To Login</Button>
            </div>

          </CardBody>
        </Card>
        <NavLink id='loginlink' tag={Link} to='/login'></NavLink>
      </div>
    );
  }

  render() {

    const first = this.slideOne();
    const second = this.slideTwo();

    return (

      <Container>

        <Row style={{ height: '80vh' }}>
          <Col sm='12'>
            <div className='dead-center h-100'>
              <Collapse isOpen={true}>
                <Collapse isOpen={this.state.isOpen1}>
                  {first}
                </Collapse>
                <Collapse isOpen={this.state.isOpen2}>
                  {second}
                </Collapse>
              </Collapse>

            </div>
          </Col>

        </Row>
      </Container>

    );
  }
}
