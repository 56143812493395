import React from 'react';
import { Row, Col, Button, Container, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
// import { Link } from 'react-router-dom';
export default class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fName: '',
      lName: '',
      pword: '',
      pwordConfirm: '',
      pwordValid: null,
      pwordInValid: null,
      feedback: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const data = { id: urlParams.get('id') };
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/checkAddUser', {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(res => {
        // console.log(res);
      });
  }

  handleChange() {
    const name = event.target.name;
    const value = event.target.value;
    const state = {};
    if (name === 'pword') {
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
      const test = regex.test(value);
      if (!test) {
        state.feedback = 'Password Is Not Strong';
        state.pwordValid = false;
        state.pwordInValid = true;
      } else {
        state.feedback = 'Password Is Strong';
        state.pwordValid = true;
        state.pwordInValid = false;
      }
    }
    state[name] = value;
    this.setState(state);
  }

  handleSubmit() {
    if (this.state.pword === this.state.pwordConfirm) {
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
      const test = regex.test(this.state.pword);
      if (test) {
        alert('good');
      } else {
        alert('bad');
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/addUser', {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify({
        id: urlParams.get('id'),
        firstName: this.state.fName,
        lastName: this.state.lName,
        password: this.state.pword
      })
    })
      .then(res => res.json())
      .then(res => {
        // console.log(res);
      });
  }

  render() {
    if (this.state.fName !== '' && this.state.lName !== '' && this.state.pword !== '' && this.state.pwordConfirm !== '') {
      var btnDisable = false;
      var btnColor = 'primary';
    } else {
      btnDisable = true;
      btnColor = 'secondary';
    }
    return (
      <Container>
        <Row>
          <Col sm='12' md={{ size: 6, offset: 3 }} className='dead-center' style={{ height: '100vh' }}>
            <Form style={{ boxShadow: '0 0 20px grey', borderRadius: '30px' }} className='w-75 ml-auto mr-auto'>
              <FormGroup style={{ backgroundColor: '#0D9DFE', borderTopRightRadius: '30px', borderTopLeftRadius: '30px', height: '120px' }} className='text-center dead-center text-white bold'>
                <h1>Sign Up</h1>
              </FormGroup>
              <br />
              <FormGroup className='w-75 ml-auto mr-auto'>
                <Label className='bold'>
                  First Name
                </Label>
                <Input id='fName' name='fName' value={this.state.fName} onChange={this.handleChange} placeholder='First Name'></Input>
              </FormGroup>
              <FormGroup className='w-75 ml-auto mr-auto'>
                <Label className='bold'>
                  Last Name
                </Label>
                <Input id='lName' name='lName' value={this.state.lName} onChange={this.handleChange} placeholder='Last Name'></Input>
              </FormGroup>
              <FormGroup className='w-75 ml-auto mr-auto'>
                <Label className='bold'>
                  Password
                </Label>
                <Input invalid={this.state.pwordInValid} valid={this.state.pwordValid} onKeyUp={() => {
                  if (event.keyCode === 13 && btnDisable === false) {
                    this.handleSubmit();
                  }
                }} name='pword' value={this.state.pword} onChange={this.handleChange} type='password' placeholder='Password'></Input>
                <FormFeedback>{this.state.feedback}</FormFeedback>
                <FormFeedback valid>{this.state.feedback}</FormFeedback>
              </FormGroup>
              <FormGroup className='w-75 ml-auto mr-auto'>
                <Label className='bold'>
                  Confirm Password
                </Label>
                <Input onKeyUp={() => {
                  if (event.keyCode === 13 && btnDisable === false) {
                    this.handleSubmit();
                  }
                }} name='pwordConfirm' value={this.state.pwordConfirm} onChange={this.handleChange} type='password' placeholder='Confirm Password'></Input>
              </FormGroup>
              <FormGroup className='w-75 ml-auto mr-auto pb-5'>
                <Button color={btnColor} id='loginlink' onClick={this.handleSubmit} /* tag={Link} to='/login' */>Create Your Account</Button>
                {/* {errorAlert} */}
              </FormGroup>

            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
