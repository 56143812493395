import React from 'react';
import Footer from './footer.jsx';
import { Col, Row, Table, Container, UncontrolledCollapse, Button } from 'reactstrap';
// import { Link } from 'react-router-dom';
export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  renderCorporateCollapse() {
    const more10 = '10 +';
    return (
      <div className='d-md-none'>
        <Button color="primary" id="togglerc">
          Corporate
        </Button>
        <UncontrolledCollapse toggler="#togglerc">
          <Table className='mb-5' bordered striped>
            <thead style={{ backgroundColor: 'rgb(46, 162, 225)', color: 'white' }}>
              <tr>
                <th></th>
                <th className='text-center'>
                  <h4 className='bold'>Corporate</h4>
                  <h5 className='price-th bold'>$200</h5>
                </th>
              </tr>
            </thead>
            <tbody className='bold'>
              <tr>
                <td>Discount</td>
                <td className='check'>20%</td>
              </tr>
              <tr>
                <td>Users</td>
                <td className='check'>{more10}</td>
              </tr>
              <tr>
                <td>100 + Resorts</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Unlimited Downloads</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Unlimited Searches</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Account Management</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Access To Site Updates</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Access To Weekly Resort Updates</td>
                <td className='check'>&#x2714;</td>
              </tr>
            </tbody>
          </Table>
        </UncontrolledCollapse>
      </div>
    );
  }

  renderCollapse() {
    const less10 = '1 - 9';
    return (
      <div className='d-md-none'>
        <Button className='d-md-none' color="primary" id="toggler">
          Business
        </Button>
        <UncontrolledCollapse toggler="#toggler">
          <Table className='mb-5' bordered striped>
            <thead style={{ backgroundColor: 'rgb(46, 162, 225)', color: 'white' }}>
              <tr>
                <th></th>
                <th className='text-center'>
                  <h4 className='bold'>Business</h4>
                  <h5 className='price-th bold'>$250</h5>
                </th>
              </tr>
            </thead>
            <tbody className='bold'>
              <tr>
                <td>Discount</td>
                <td className='check'>0%</td>
              </tr>
              <tr>
                <td>Users</td>
                <td className='check'>{less10}</td>
              </tr>
              <tr>
                <td>100 + Resorts</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Unlimited Downloads</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Unlimited Searches</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Account Management</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Access To Site Updates</td>
                <td className='check'>&#x2714;</td>
              </tr>
              <tr>
                <td>Access To Weekly Resort Updates</td>
                <td className='check'>&#x2714;</td>
              </tr>
            </tbody>
          </Table>
        </UncontrolledCollapse>
      </div>
    );
  }

  renderTable() {
    const less10 = '1 - 9';
    const more10 = '10 +';
    return (
      <Table className='mb-5 hide-on-med-and-down' bordered striped>
        <thead style={{ backgroundColor: 'rgb(46, 162, 225)', color: 'white' }}>
          <tr>
            <th></th>
            <th className='text-center'>
              <h4 className='bold'>Business</h4>
              <h5 className='price-th bold'>$250</h5>
            </th>
            <th className='text-center'>
              <h4 className='bold'>Corporate</h4>
              <h5 className='price-th bold'>$200</h5>
            </th>
          </tr>
        </thead>
        <tbody className='bold'>
          <tr>
            <td>Discount</td>
            <td className='check'>0%</td>
            <td className='check'>20%</td>
          </tr>
          <tr>
            <td>Users</td>
            <td className='check'>{less10}</td>
            <td className='check'>{more10}</td>
          </tr>
          <tr>
            <td>100 + Resorts</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Complete Owner Information</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Unlimited Downloads</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Unlimited Searches</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Account Management</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Access To Site Updates</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
          <tr>
            <td>Access To Weekly Resort Updates</td>
            <td className='check'>&#x2714;</td>
            <td className='check'>&#x2714;</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const theTable = this.renderTable();
    const collapse = this.renderCollapse();
    const collapseC = this.renderCorporateCollapse();
    return (
      <div className=''>
        <Row style={{ height: '15vh' }}></Row>
        <Row>
          <Col sm='12' md={{ size: 4, offset: 4 }}>
            <h3 className='text-center'>Pricing And Plans</h3>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Container>
            <Col sm='12' md={{ size: 8, offset: 2 }}>
              {theTable}
              {collapse}
              {collapseC}
            </Col>
          </Container>
        </Row>
        <Footer />
      </div>

    );

  }
}
