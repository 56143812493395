import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class ProtectedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    if (this.props.loggedIn) {
      return (
        <Route path={this.props.path} {...this.props} component={this.props.component} />
      );
    } else if (!this.props.loggedIn) {
      return (
        <Redirect to={this.props.redirect} />
      );
    }

  }
}
/*
  required:
    location : string
  optional:
    exeptions : array or single string

*/
export function forcedRedirect(location, exeptions = []) {
  if (!Array.isArray(exeptions)) {
    const tempExeptions = exeptions;
    exeptions = [];
    exeptions.push(tempExeptions);
  }
  exeptions.push(location);
  const pass = exeptions.map(exeption => {
    if (window.location.pathname !== exeption) {
      return false;
    }
    return true;
  });
  if (pass.includes(true)) {
    return false;
  } else {
    window.location.assign(location);
    return true;
  }

}
