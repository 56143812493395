import React from 'react';
// import { Link, NavLink } from 'react-router-dom';
import {
  Button, CardBody, Card, Container, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, CardFooter
} from 'reactstrap';
import * as Cookie from 'js-cookie';

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      emailBoxes: [
      ]

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createEmailBoxes = this.createEmailBoxes.bind(this);
    this.removeEmailBoxes = this.removeEmailBoxes.bind(this);
    this.collectEmails = this.collectEmails.bind(this);
  }

  componentDidMount() {
    const inputs = this.state.emailBoxes;
    inputs.push(
      <Row key={0} className='mb-1 mt-2'>
        <Col sm='12'>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>A</InputGroupText>
            </InputGroupAddon>
            <Input name='input' className='is-invalid' id='box0' type='search' placeholder='Enter Cardholder Email' onChange={() => {
              const inputbox = document.getElementById('box0');
              if (inputbox.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)) {
                inputbox.classList.remove('is-invalid');
                inputbox.classList.add('is-valid');
              } else {
                inputbox.classList.add('is-invalid');
                inputbox.classList.remove('is-valid');
              }
            }} />
            <InputGroupAddon addonType="append">
              <InputGroupText></InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    );
    this.setState({ emailBoxes: inputs });
  }

  handleSubmit(qty = 1) {

    event.preventDefault();
    const email = document.getElementById('box0').value;
    const discount = document.getElementById('discount').value;
    window.location.assign(`/checkout.html?qty=${qty}&e=${email}&d=${discount.toLowerCase()}`);
  }

  removeEmailBoxes(item) {
    let inputs = this.state.emailBoxes;
    inputs = inputs.filter(box => box !== item);
    this.setState({ emailBoxes: inputs });
  }

  collectEmails() {
    const emailsElems = document.getElementsByClassName('is-valid');
    const elemLength = emailsElems.length;
    const emails = [];
    if (emailsElems.length > 1) {
      for (let i = 0; i < elemLength; i++) {
        if (i === 0) {
          emails.push({
            email: emailsElems[i].value,
            status: 'AM'
          }
          );
          continue;
        }
        if (document.getElementById(`admin${i}`).checked) {
          emails.push({
            email: emailsElems[i].value,
            status: 'AM'
          }
          );
        } else {
          emails.push({
            email: emailsElems[i].value,
            status: 'RE'
          }
          );
        }

      }
    } else {
      emails.push({
        email: emailsElems[0].value,
        status: 'AM'
      }
      );
    }
    return emails;
  }

  createEmailBoxes(num, item) {
    const inputs = this.state.emailBoxes;
    // if (inputs.indexOf(item) + 1 === inputs.length || inputs.indexOf(item) === -1) {
    //   if (inputs.indexOf(item) + 2 < inputs.length) return;
    const id = 'box' + this.state.key;
    const adminId = 'admin' + this.state.key;
    const newInput = (
      <Row key={this.state.key} className='mb-1'>
        <Col sm='12'>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className='pl-4'>A<Input id={adminId} type='checkbox'></Input></InputGroupText>
            </InputGroupAddon>
            <Input name='input' className='is-invalid' id={id} type='search' placeholder='Enter User Email' onChange={() => {
              const inputbox = document.getElementById(id);
              if (inputbox.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)) {
                inputbox.classList.remove('is-invalid');
                inputbox.classList.add('is-valid');
              } else {
                inputbox.classList.add('is-invalid');
                inputbox.classList.remove('is-valid');
              }
            }} />
            <InputGroupAddon className="cursor" addonType="append">
              <InputGroupText className='cursor' onClick={() => {
                this.removeEmailBoxes(newInput);
              }} >x</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    );
    inputs.push(newInput);
    this.setState({ emailBoxes: inputs, key: this.state.key + 1 });
    // }
  }

  v2() {
    const newInputs = this.state.emailBoxes;
    if (newInputs.length < 10) {
      var baseCost = 250;
      var liscenseCost = baseCost * newInputs.length;
      var liscenseText = `${newInputs.length} * $${baseCost} = $${liscenseCost}`;
    } else {
      baseCost = 200;
      liscenseCost = baseCost * newInputs.length;
      liscenseText = `${newInputs.length} * $${baseCost} = $${liscenseCost}`;
    }
    var buttonText = 'Buy Liscense';
    if (this.state.emailBoxes.length > 1) {
      buttonText = 'Buy Liscenses';
    }
    return (
      <div>
        <Card style={{ borderRadius: '30px' }}>
          <h3 style={{ borderTopLeftRadius: '30px', borderTopRightRadius: '30px', backgroundColor: '#0D9DFE' }} className='text-center text-white pb-3 pt-3'>Enter User Emails</h3>
          <p className='ml-2'>* Check A To Make User An Administrator</p>
          <p className='ml-2'><Input type="text" id="discount" placeholder="Discount Code"></Input> </p>
          <hr />
          <CardBody>
            <Row>
              <Col>
                {newInputs}
              </Col>
            </Row>
            <Row className='mt-2 '>
              <Col className='d-flex flex-direction-column align-items-center' sm='12' md='6'>
                <h5 >{liscenseText}</h5>
              </Col>
              <Col sm='12' md='6' className=''>
                <Button className='float-right' color='info' onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  this.createEmailBoxes(this.state.emailBoxes.length + 1);
                }}>Add Additional User</Button>
              </Col>

            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col sm='12'>
                <Button color='primary' onClick={() => {
                  const emailInvalid = document.getElementsByClassName('is-invalid').length;
                  if (emailInvalid > 0) {
                    alert('One Or More Emails Are Invalid');
                  } else {

                    const emails = this.collectEmails();
                    const firstemail = document.getElementById('box0').value;
                    Cookie.set('firstemail', firstemail, { expires: 2 });
                    Cookie.set('list', { emails }, { expires: 2 });
                    const discount = document.getElementById('discount').value;
                    window.location.assign(`/checkout.html?qty=${this.state.emailBoxes.length}&e=${firstemail}&d=${discount.toLowerCase()}`);
                  }
                }}>{buttonText}</Button>

              </Col>
            </Row>

          </CardFooter>

        </Card>

      </div>
    );
  }

  render() {
    const v2 = this.v2();

    return (

      <Container>
        <Row style={{ height: '15vh' }} />
        <Row style={{ height: '80vh' }}>
          <Col sm='12' md={{ size: 6, offset: 3 }}>
            <div className='h-100'>
              {v2}
            </div>
          </Col>

        </Row>
      </Container>

    );
  }
}
