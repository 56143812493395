import React from 'react';
import { Container, Row, Col } from 'reactstrap';
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (<div className="footer">
      <Container className='pt-4'>
        <Row>
          <Col sm='12' md='4'>
            <ul className='footerUl'>
              <li className='bold'>Contact</li>
              <li className=''><a href='/' onClick={() => { event.preventDefault(); }}>contact@b2listings.com</a></li>
            </ul>
          </Col>
          <Col sm='12' md='4'>
            <ul className='footerUl'>
              <li className='bold'>Legal</li>
              <li className=''><a href='/' onClick={() => { event.preventDefault(); }}>Privacy Policy</a></li>
              <li className=''><a href='/' onClick={() => { event.preventDefault(); }}>Terms Of Service</a></li>
            </ul>

          </Col>
          <Col sm='12' md='4'>
            <ul className='footerUl'>
              <li className='bold'>Placeholder</li>
              <li className=''><a href='/' onClick={() => { event.preventDefault(); }}>Item 1</a></li>
              <li className=''><a href='/' onClick={() => { event.preventDefault(); }}>Item 2</a></li>
            </ul>

          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <p>&#169; Copyright 2020 B2Listings </p>
          </Col>
        </Row>
      </Container>
    </div>);
  }
}
