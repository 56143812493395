import React from 'react';
import { BrowserRouter as Router, Switch, Route/* , Link  */ } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import MainScreen from './mainScreen2.jsx';
import NavBar from './navbar';
import Dashboard from './dashboard.jsx';
import Login from './login.jsx';
import MyAccount from './myAccount.jsx';
import Pricing from './pricing.jsx';
import About from './about.jsx';
import SignUp from './signup.jsx';
import Success from './success.jsx';
import * as Cookie from 'js-cookie';
import * as CryptoJS from 'crypto-js';
import CreateAccount from './createAccount.jsx';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      test: 'watch me change back',
      filterShow: false
    };
    this.setUser = this.setUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.goToLink = this.goToLink.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.theme = '#0D9DFE';
  }

  componentDidMount() {
    if (this.checkIfLoggedIn()) {
      this.setState({ user: true });
    }
  }

  filterToggle(toggle) {
    this.setState({ filterShow: toggle });
  }

  checkIfLoggedIn() {
    if (Cookie.get('log')) {
      const toDecrypt = CryptoJS.AES.decrypt(Cookie.get('log'), Cookie.get('usere'));
      const decrypted = toDecrypt.toString(CryptoJS.enc.Utf8);
      if (decrypted === 'true') {
        return true;
      } else {
        return false;
      }
    }
  }

  setUser() {
    this.setState({ user: true });
  }

  logoutUser() {
    event.preventDefault();
    Cookie.remove('type');
    Cookie.remove('usere');
    Cookie.remove('usern');
    Cookie.remove('log');
    this.setState({ user: false });

  }

  goToLink(id) {
    document.getElementById(id).click();
  }

  render() {

    const nav = (<NavBar filter={this.state.filterShow} link={this.goToLink} logoutUser={this.logoutUser} userName={this.state.user} />);
    var loggedIn = this.checkIfLoggedIn();
    return (
      <Router>
        {nav}
        <div className='w-100' />
        <div>
          <Switch>
            <Route exact path="/">
              <MainScreen />
            </Route>
            <Route exact strict path="/login">
              <Login setUser={this.setUser} />
            </Route>
            <Route exact strict path="/pricing">
              <Pricing />
            </Route>
            <Route exact strict path="/about">
              <About />
            </Route>
            <Route exact strict path="/signup">
              <SignUp login={this.setUser} />
            </Route>
            <Route exact strict path="/success">
              <Success login={this.setUser} />
            </Route>
            <Route exact strict path="/createaccount">
              <CreateAccount />
            </Route>
            <ProtectedRoute redirect='/login' path="/dashboard" loggedIn={loggedIn}>
              <Dashboard toggle={this.filterToggle} />
            </ProtectedRoute>
            <ProtectedRoute redirect='/login' path="/myaccount" loggedIn={loggedIn} component={MyAccount} />
          </Switch>
        </div>
        {/* <NavLink id='loginlink' tag={Link} to='/dashboard'></NavLink> */}
      </Router>);
  }
}
