import React from 'react';
import { Row, Col, Container, Input, InputGroup, Button, Spinner } from 'reactstrap';
import SearchResults from './searchResults.jsx';
import Footer from './footer.jsx';
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      resortNames: [],
      finishedSearch: null,
      isOpen: true,
      cityFilter: '',
      fixedSearch: false,
      resultCount: 0
    };
    this.fheight = 0;
    this.addrList = [];
    this.updateSearch = this.updateSearch.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount() {
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/getResorts')
      .then(res => res.json())
      .then(data => {
        const state = {};
        state.resortNames = data;
        // searchBox.disabled = false;
        const filters = document.getElementById('filters');
        this.fheight = filters.offsetHeight;
        this.setState(state);
      });
  }

  updateSearch() {
    let search = event.target.value;
    const lastinput = search.charAt(search.length - 1);
    if (search.length === 1 && lastinput === ' ') {
      search = '';
    } else {
      if (isNaN(lastinput) || lastinput === ' ' || lastinput === '') {
        const state = {};
        if (!this.state.isOpen) {
          state.isOpen = true;
        }
        state.searchValue = search;
        this.setState(state);
      }
    }

  }

  resetFilter() {
    const state = {};
    state.searchValue = '';
    state.cityFilter = '';
    this.setState(state);
  }

  handleFilters() {
    const name = event.target.name;
    const value = event.target.value;
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  renderFilters(count) {
    const numResults = 'Results: ' + count;
    return (
      <InputGroup id='filters' className='pt-3'>
        <Col sm='12' md='2'>
          <Input className='text-center' disabled placeholder={numResults}></Input>
        </Col>
        <Col sm='12' md='4'>
          <Input value={this.state.cityFilter} name='cityFilter' type="select" id="cityFilter" onChange={this.handleFilters}>
            <option value=''>Filter By City</option>
            <option value='Myrtle Beach'>Myrtle Beach</option>
            <option value='North Myrtle Beach'>North Myrtle Beach</option>
            <option value='Murrells Inlet'>Murrells Inlet</option>
            <option value='Surfside Beach'>Surfside Beach</option>
          </Input>
        </Col>
        <Col sm='12' md='4'>
          <Input id='filterSearch' type='search' placeholder='Filter By Resort' onChange={this.updateSearch} value={this.state.searchValue} />
        </Col>
        <Col sm='12' md='2'>
          <Button onClick={this.resetFilter}>Reset Filters</Button>
        </Col>
      </InputGroup>
    );
  }

  renderCards(data = this.state.resortNames) {
    if (this.state.cityFilter) {
      data = data.filter(value => value.City === this.state.cityFilter);
    }
    let runningCount = 0;
    const cards = data.map((value, count) => {
      if (value.otherName.toLowerCase().includes(this.state.searchValue.toLocaleLowerCase())) {
        runningCount++;
        return (
          <Col sm='12' md='3' key={count} >
            <SearchResults value={value.averageValue} bd1={value.oneBedrooms} bd2={value.twoBedrooms} bd3={value.threeBedrooms} title={value.otherName} city={value.City} address='123 Cherry Lane' />
          </Col>
        );
      }
    });
    let filteredCards = cards.filter(value => value != null);
    if (filteredCards.length === 0) {
      filteredCards = null;
    }
    return {
      filteredCards: filteredCards,
      runningCount: runningCount
    };
  }

  render() {
    var cards = this.renderCards();
    var search = cards.filteredCards;
    // var search = 0;
    var filters = this.renderFilters(cards.runningCount);
    if (!search) {
      return (
        <div id="outer" className='transparent' style={{ height: '20vh' }}>
          <div className='pb-5 bg-theme-color'>
            <Container className='' style={{ height: 'fit-content' }}>
              <Row className='mt-5 pt-5 dead-center'>
                {filters}
              </Row>
            </Container>
          </div>

          {/* <hr /> */}
          <div className='my-container text-center dead-center' style={{ height: '80vh' }}>
            <Row>
              <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
            </Row>
          </div>
        </div>
      );
    }
    return (
      <div id="outer" className='transparent' style={{ height: '100%' }}>
        <div id='filters' className='pb-5 bg-theme-color'/*  style={{ position: 'fixed', zIndex: '1000', width: '100vw' }} */>
          <Container className='' style={{ height: 'fit-content' }}>
            <Row className='mt-5 pt-3'>
              {filters}
            </Row>
          </Container>
        </div>

        <div className='my-container'>
          <Row>
            {search}
          </Row>
        </div>

        <Footer />
      </div>
    );

  }
}
