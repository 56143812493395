import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container, Button, Form, FormGroup, Label, Input, Spinner, Table } from 'reactstrap';
import classnames from 'classnames';
import * as Cookie from 'js-cookie';
import * as CryptoJS from 'crypto-js';
export default class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'User',
      activeTab: '1',
      users: [],
      data: {
        firstName: <Spinner size='sm' color='primary' />,
        lastName: <Spinner size='sm' color='primary' />,
        company: <Spinner size='sm' color='primary' />,
        endDate: <Spinner size='sm' color='primary' />,
        licenses: <Spinner size='sm' color='primary' />
      },
      endDate: <Spinner size='sm' color='primary' />
    };
    this.toggle = this.toggle.bind(this);
    this.changePword = this.changePword.bind(this);
  }

  componentDidMount() {
    const user = Cookie.get('usern');
    const settings = {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify({
        email: Cookie.get('usere')
      })
    };
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/myAccount ', settings)
      .then(response => response.json())
      .then(data => {
        const enddate = this.convertUnix(data.endDate);
        this.setState({ users: data.users, name: user, data: data, endDate: enddate });
      });

  }

  convertUnix(unixcode) {
    const a = new Date(unixcode * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const finalDate = `${month}/${date}/${year}`;
    return (finalDate);
  }

  changePword() {
    const settings = {
      method: 'POST',
      type: 'application/json',
      body: JSON.stringify({
        email: Cookie.get('usere'),
        oldPassword: document.getElementById('curPass').value,
        newPassword: document.getElementById('newPass').value
      })
    };
    fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/changePassword  ', settings)
      .then(response => response.json())
      .then(data => {
        if (data === 'success') {
          alert('success');
        } else {
          alert('failed');
        }
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  // Account Tab
  tab1() {
    return (
      <>
        <tr>
          <td className="bold" colSpan="3" style={{ borderBottom: '1px black solid' }}><h4>Account</h4></td>
        </tr>
        <tr>
          <td>First Name</td>
          <td>{this.state.data.firstName}</td>
          <td></td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{this.state.data.lastName}</td>
          <td></td>
        </tr>
        <tr>
          <td>Registerd Email</td>
          <td>{Cookie.get('usere')}</td>
          <td></td>
        </tr>
        <tr>
          <td>Password</td>
          <td>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</td>
          <td><a onClick={() => {
            event.preventDefault();
            this.toggle('2');
          }} href='/'>Edit</a></td>
        </tr>
        <tr>
          <td className="" colSpan="3"></td>
        </tr>

      </>
    );
  }

  // change password tab
  tab2() {
    return (
      <TabPane tabId="2">
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <h2 className="text-center" >Change Password</h2>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md={{ size: 4, offset: 4 }}>
            <Form>
              <FormGroup>
                <Label>Current Password</Label>
                <Input type='password' id='curPass' placeholder='Current Password' />
              </FormGroup>
              <FormGroup>
                <Label>New Password</Label>
                <Input type='password' id='newPass' placeholder='New Password' />
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input type='password' placeholder='Confirm New Password' />
                <br />
                <Button onClick={() => {
                  event.preventDefault();
                  this.changePword();
                }}>Send</Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </TabPane>
    );
  }

  // Management tab
  tab3() {
    return (
      <>
        <tr>
          <td className="bold" colSpan="3" style={{ borderBottom: '1px black solid' }}><h4>Managment</h4></td>
        </tr>
        <tr>
          <td>Company</td>
          <td>{this.state.data.company}</td>
          <td></td>
        </tr>
        <tr>
          <td>Number Of Licenses</td>
          <td>{this.state.data.licenses}</td>
          <td><a onClick={() => {
            event.preventDefault();
          }} href='/'>Add Licenses</a></td>
        </tr>
        <tr>
          <td>Plan Renew Date</td>
          <td>{this.state.endDate}</td>
          <td><a onClick={() => {
            event.preventDefault();
          }} href='/'>Cancel Subscription</a></td>
        </tr>
      </>
    );
  }

  // Show All Users Tab
  tab4() {
    var body = this.state.users.map((user, index) => {
      const email = user.email;
      const fname = user.firstName;
      const lname = user.lastName;
      let status = '';
      if (user.status === 'AM') {
        status = 'Account Manager';
      } else {
        status = 'Standard User';
      }
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{fname}</td>
          <td>{lname}</td>
          <td>{email}</td>
          <td>{status}</td>
          <td><Button color='danger'>Revoke</Button></td>
        </tr>
      );
    });
    return (
      <TabPane tabId="4">
        <Row>
          <Col sm="12">
            <h2>Users In Your Corporation</h2>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md={{ size: 8, offset: 2 }}>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Registered Email</th>
                  <th>Access Type</th>
                  <th>Access</th>
                </tr>
              </thead>
              <tbody>
                {body}
              </tbody>
            </Table>
          </Col>
        </Row>
      </TabPane>
    );
  }

  render() {
    var tab1 = this.tab1();
    var tab2 = this.tab2();
    var tab3 = null;
    var tab4 = null;
    var accountType = null;
    const toDecrypt = CryptoJS.AES.decrypt(Cookie.get('type'), Cookie.get('usere'));
    const decrypted = toDecrypt.toString(CryptoJS.enc.Utf8);
    if (decrypted === 'AM') {
      tab3 = this.tab3();
      tab4 = this.tab4();
      accountType = (
        <>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' }, 'cursor theme-color')}
              onClick={() => { this.toggle('4'); }}
            >
            Users
            </NavLink>
          </NavItem>
        </>
      );
    }
    return (
      <Container>
        <Row style={{ height: '15vh' }}></Row>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' }, 'cursor theme-color')}
                onClick={() => { this.toggle('1'); }}
              >
              Account
              </NavLink>
            </NavItem>
            {accountType}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm='12' md={{ size: 8, offset: 2 }}>
                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tab1}
                      {tab3}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>
            {/* {tab1} */}
            {tab2}
            {/* {tab3} */}
            {tab4}
          </TabContent>
        </div>
      </Container>
    );

  }
}
