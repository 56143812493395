import React from 'react';
import { makeCSV, download } from './libOfFunctions.jsx';
import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';

export default class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {
    const city = 'City: ' + this.props.city;
    const bd1 = '1 Bedrooms: ' + this.props.bd1;
    const bd2 = '2 Bedrooms: ' + this.props.bd2;
    const bd3 = '3 Bedrooms: ' + this.props.bd3;
    const value = 'Average Value: $' + Math.round(this.props.value);
    const btnId = `downloadButton_${this.props.title}`;
    const settings = {
      method: 'POST',
      body: JSON.stringify([this.props.title])
    };
    var buttonText = 'Download Report';

    return (
      <div>
        <Card className='search-card mt-3 mb-3' style={{ borderRadius: '30px' }}>
          {/* <CardImg top width="100%" src="/images/318x180.svg" alt="Card image cap" /> */}
          <CardTitle style={{ color: 'white', backgroundColor: '#0D9DFE', borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }} className='bold'><h5 className='pt-3 pb-3 ml-3'>{this.props.title}</h5></CardTitle>
          <CardBody>
            <CardSubtitle className='pb-1'>{city}</CardSubtitle>
            <CardSubtitle className='pb-1'>{bd1}</CardSubtitle>
            <CardSubtitle className='pb-1'>{bd2}</CardSubtitle>
            <CardSubtitle className='pb-1'>{bd3}</CardSubtitle>
            <CardSubtitle className='pb-1'>{value}</CardSubtitle>
            <Button id={btnId} onClick={() => {
              const btn = document.getElementById(btnId);
              btn.innerHTML = 'Generating Report';
              btn.disabled = true;
              fetch('https://rrkxkkbwz8.execute-api.us-east-1.amazonaws.com/dev/getCondoData', settings)
                .then(res => res.json())
                .then(data => {
                  if (data.length > 0) {
                    download(this.props.title + ' Report', makeCSV(data));
                    btn.innerHTML = buttonText;
                    btn.disabled = false;
                  } else {
                    btn.innerHTML = 'No Data Available';
                  }
                });
            }} color='link' className='mr-auto'><i className="fas fa-file-excel"></i>{buttonText}</Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}
