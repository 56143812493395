import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  /* Input, Button, */ Container
} from 'reactstrap';
import { Link } from 'react-router-dom';
export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  clickHandler() {
    event.preventDefault();
  }

  nonLoggedIn() {
    return (
      <Navbar color="dark" dark fixed="top" expand="md" style={{ boxShadow: '2px 0 5px black' }}>
        <Container>
          <NavbarBrand tag={Link} to='/'>B2 Listings</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto right" navbar>
              <NavItem>
                <NavLink name='about' className="text-white" /* tag={Link} to='/#works' */ href='/#works'>How It Works</NavLink>
              </NavItem>
              <NavItem>
                <NavLink name='pricing' className="text-white" /* tag={Link} to='/pricing' */ href='/#price'>Pricing And Plans</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-info bold" name='login' tag={Link} to='/login'>Login</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="text-primary bold" name='signup' tag={Link} to='/signup'>Signup</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }

  loggedIn() {
    return (
      <Navbar color="dark" dark fixed="top" expand="md" style={{ boxShadow: '2px 0 5px black' }}>
        <Container>
          <NavbarBrand tag={Link} to='/dashboard'>B2 Listings</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto right" navbar>
              <NavItem>
                <NavLink name='pricing' className="text-white" tag={Link} to='/dashboard'>Dashboard</NavLink>
              </NavItem>
              <NavItem>
                <NavLink name='pricing' className="text-white" tag={Link} to='/myaccount'>My Account</NavLink>
              </NavItem>
              <NavItem className='pr-5'>
                <NavLink className="text-info bold" onClick={() => { this.props.logoutUser(); }} name='logout' tag={Link} to='/login'>Log Out</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }

  render() {
    if (!this.props.userName) {
      const buildNav = this.nonLoggedIn();
      return (
        <div>
          {buildNav}
        </div>
      );
    } else {
      const buildNav = this.loggedIn();
      return (
        <div>
          {buildNav}
        </div>
      );
    }
  }
}
